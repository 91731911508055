<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Verificar depósito"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  El/la responsable de caja debe verificar el comprobante de
                  <b>Depósito Bancario</b> realizado por el/la postulante y dar
                  su VoBo en modalidad <b>A</b>.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <!-- <v-card-title>Formulario de Postulación y Aptitud</v-card-title> -->
              <v-card-text>
                <v-form ref="formp" @submit.prevent="getPostulantes()">
                  <v-row class="mt-1">
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="options.search"
                        label="CI o DNI, nombre, apellidos"
                        :rules="[(v) => !!v || '']"
                        @input="changeSearch"
                        hint="1234567 o 1234567-1G"
                        hide-details
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-btn
                        type="submit"
                        large
                        color="primary"
                        :loading="load_search"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="postulantes"
                  :page.sync="options.page"
                  :items-per-page="options.itemsPerPage"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.num`]="{ item }">
                    <td>
                      {{
                        (options.page - 1) * options.itemsPerPage +
                        (postulantes.indexOf(item) + 1)
                      }}
                    </td>
                  </template>
                  <template v-slot:[`item.nombre`]="{ item }">
                    <td>
                      {{ item.a_persona.paterno }} {{ item.a_persona.materno }}
                      {{ item.a_persona.nombres }}
                    </td>
                  </template>
                  <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                    <td>
                      {{ item.a_persona.fecha_nacimiento | formatdate }}
                    </td>
                  </template>
                  <!-- <template v-slot:[`item.accion`]="{ item }">
                    <td>
                      <v-btn
                        small
                        color="success"
                        @click="
                          confirmPostulante(
                            item.id,
                            item.a_persona.paterno,
                            item.a_persona.materno,
                            item.a_persona.nombres,
                            item.deposito.id
                          )
                        "
                      >
                        Verificar
                      </v-btn>
                    </td>
                  </template> -->
                  <template v-slot:[`item.numero_deposito`]="{ item }">
                    <td class="subtitle-1 font-weight-bold">
                      {{ item.deposito.numero }}
                    </td>
                  </template>
                  <template v-slot:[`item.fecha_deposito`]="{ item }">
                    <td class="subtitle-1 font-weight-bold">
                      {{ item.deposito.fecha | formatdate }}
                    </td>
                  </template>
                </v-data-table>
                <div class="v-data-footer">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <div class="v-data-footer__select">
                        Filas por página:
                        <v-select
                          :items="[10, 20, 30, 50, 100]"
                          v-model="options.itemsPerPage"
                          @change="changePerPage()"
                          hide-details
                          class="my-0"
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="8" class="text-center">
                      <v-pagination
                        v-model="options.page"
                        :length="options.pageCount"
                        :total-visible="5"
                        @input="changePage"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="type_state === 2">
                  <span class="red--text">
                    No hay registro con la
                    <b>Cédula Identidad o DNA</b> ingresada.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="el proceso de admisión"
        ></notify-card>
      </div>
    </v-container>
    <v-dialog v-model="confirmDialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text class="text-center">
          <!-- <p class="text-center">¿Está seguro(a) de confirmar el registro?</p> -->
          <p>
            ¿Está seguro(a) de habilitar al postulante
            <b class="blue--text">{{ postulante }}</b>?
            <!-- para la
            <b>Prueba de Aptitud</b>? -->
          </p>
          <p>Dando su VoBo de que el <b>depósito bancario</b> es correcto.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="load_send"
            text
            @click="checkPostulante()"
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="confirmDialog = false"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Service from "../utils/general";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "admision-verificar-caja",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    loading: false,
    load_search: false,
    load_send: false,
    type_state: 1,
    cedula_dni: "",
    convocatoria: {
      id: null,
    },
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "num",
      },
      { text: "Apellidos y nombres", value: "a_persona.nombre_completo" },
      { text: "Cédula identidad", value: "a_persona.cedula_dni" },
      { text: "Complemento", value: "a_persona.complemento" },
      { text: "Fecha nacimiento", value: "a_persona.fecha_nacimiento" },
      { text: "Celular", value: "a_persona.celular" },
      { text: "Especialidad", value: "especialidad.descripcion" },
      { text: "Nro. depósito", value: "deposito.numero" },
      { text: "Fecha depósito", value: "deposito.fecha" },
      { text: "Acciones", value: "accion" },
    ],
    options: {
      search: "",
      convocatoria_id: "",
      institucion_id: "",
      estado: 1,
      sigla: "A",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    },
    postulantes: [],
    postulante: "",
    postulante_id: "",
    deposito_id:"",
    confirmDialog: false,
    user: null,
  }),
  created() {
    this.user = Service.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "root" });
    }
  },
  mounted() {
    const roles = [9, 10, 11];
    if (this.user && roles.includes(this.user.rid)) {
      this.options.institucion_id = this.user.ies;
      this.getModalidades();
    } else {
      this.$router.replace({ name: "root" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getModalidades() {
      this.notify_load = true;
      axios
        .get(Service.getUrlAPI() + "admision/modalidad", Service.getHeader())
        .then((response) => {
          this.convocatoria = response.data.convocatoria
            ? response.data.convocatoria
            : { id: null, cite: "" };
          this.options.convocatoria_id = this.convocatoria.id;
          this.notify_title = "Notificación";
          this.notify_load = false;
          this.getPostulantes();
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    },

    getPostulantes() {
      this.loading = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/postulantes",
          this.options,
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.postulantes = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount =
              Math.floor(response.data.count / this.options.itemsPerPage) + 1;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    changeSearch() {
      if (this.options.search && this.options.search.length > 2) {
        this.getPostulantes();
      }
    },

    changePerPage() {
      this.options.page = 1;
      this.getPostulantes();
    },

    changePage() {
      this.getPostulantes();
    },

    confirmPostulante(id, paterno, materno, nombres,deposito_id) {
      this.postulante = `${paterno} ${materno} ${nombres}`;
      this.postulante_id = id;
      this.deposito_id = deposito_id;
      this.confirmDialog = true;
    },

    checkPostulante() {
      this.load_send = true;
      axios
        .put(
          Service.getUrlAPI() +
            "admision/confirmar/postulante/" +
            this.postulante_id,
          {
            deposito_id:this.deposito_id
          },
          Service.getHeader()
        )
        .then((response) => {
          this.load_send = false;
          this.toast({ color: "success", text: response.data });
          this.confirmDialog = false;
          this.getPostulantes();
        })
        .catch((response) => {
          this.load_send = false;
          this.toast({ color: "error", text: response.data });
        });
    },
  },
};
</script>